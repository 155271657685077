import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import type { CheckboxProps } from 'components/commercetools-ui/atoms/checkbox';
import DiscountErrorModal from './components/dicount-codes-error-modal';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import Footer from './components/footer';
import type { Props as HeaderProps } from './components/header';
import Header from './components/header';
import Secure from './components/secure';
import Steps from './components/steps';
import type { TermsAndConditionsType } from './components/terms-and-conditions';
import TermsAndConditions from './components/terms-and-conditions';
import usePurchase from './hooks/usePurchase';
import CheckoutProvider, { useCheckout } from './provider';
import { useRouter } from 'next/navigation';
import Loqate from '../../../headless/loquate';
import OrderSummary from '../order-summary';
import Markdown from '../markdown';

export interface TermsAndConditionsData {
    country: string;
    markdownText: string;
}

export interface TermsAndConditionsAll {
    termsAndConditions: TermsAndConditionsData[];
    termsAndConditionsSecond: TermsAndConditionsData[];
    termsAndConditionsThird: TermsAndConditionsData[];
}

export interface InvalidDiscountCodeModal {
    invalidCodeTextContent: string;
    goToCartText: string;
    proceedText: string;
}

export interface CheckoutWrappedProps extends HeaderProps, TermsAndConditionsAll, InvalidDiscountCodeModal {
    B2B_CUSTOMER_INFO_INVOICE_SENDING: string;
}

const CheckoutWrapped: React.FC<CheckoutWrappedProps> = ({
    logo,
    termsAndConditions,
    termsAndConditionsSecond,
    termsAndConditionsThird,
    invalidCodeTextContent,
    goToCartText,
    proceedText,
    B2B_CUSTOMER_INFO_INVOICE_SENDING
}) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

    const [isDesktopSize] = useMediaQuery(desktop);

    const [isFinalStep, setIsFinalStep] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDiscountErrorModalOpen, setIsDiscountErrorModalOpen] = useState(false);
    const { isLoading, data: cartData, hasOutOfStockItems, removeInvalidDiscountCodes, hasInvalidDiscountCodes } = useCart();
    const router = useRouter();

    const termsAndConditionsAll: TermsAndConditionsData[][] = [termsAndConditions, termsAndConditionsSecond, termsAndConditionsThird];

    const { purchase, setIsTermsAccepted, isTermsAccepted, isDicountCodeError } = usePurchase();

    const termsAndConditionsSkeleton: TermsAndConditionsType = {};

    for (const [key, terms] of Object.entries(termsAndConditionsAll)) {
        const termForRightCountry = terms?.find(el => el.country === cartData?.billingAddress?.country || el.country === 'default');
        if (termForRightCountry) {
            termsAndConditionsSkeleton[`termsAndConditions${key}`] = {
                text: termForRightCountry.markdownText,
                checked: false
            };
        }
    }

    const [termsAndConditionsMaped, setTermsAndConditionsMaped] = useState(termsAndConditionsSkeleton);

    const handleTermsChange: CheckboxProps['onChange'] = ({ checked, name }) => {
        setTermsAndConditionsMaped(prev => ({
            ...prev,
            [name]: {
                ...prev[name],
                checked
            }
        }));
    };

    useEffect(() => {
        setIsTermsAccepted(Object.values(termsAndConditionsMaped).every(term => term.checked));
    }, [termsAndConditionsMaped, setIsTermsAccepted]);

    const handleCompetePurchase = async () => {
        setProcessing(true);
        if (!cartData) {
            return;
        }
        if (hasInvalidDiscountCodes(cartData)) {
            if (isTermsAccepted) {
                setIsModalOpen(true);
            } else {
                await purchase();
                setIsModalOpen(false);
            }
            setProcessing(false);
        } else {
            await purchase();
        }
    };

    const handleDebouncedCompetePurchase = throttle(
        async () => {
            await handleCompetePurchase();
        },
        1000,
        { trailing: false }
    );

    useEffect(() => {
        setIsDiscountErrorModalOpen(isDicountCodeError);
    }, [isDicountCodeError]);

    const handleModalClick = async () => {
        await removeInvalidDiscountCodes();
        await purchase();
    };

    const { processing, setProcessing } = useCheckout();

    return (
        <div className="min-h-screen lg:bg-checkout-bg">
            <Header logo={logo} />
            <div className="lg:mx-40">
                <Secure />
                <div className="flex flex-col items-start gap-40 lg:flex-row">
                    <Steps
                        onFinalStepChange={setIsFinalStep}
                        termsAndConditions={termsAndConditionsMaped}
                        handleTermsChange={handleTermsChange}
                        b2bCustomerInfoInvoiceSending={B2B_CUSTOMER_INFO_INVOICE_SENDING}
                    />
                    <OrderSummary
                        className="border-checkout-border border-t bg-white px-16 max-lg:w-full md:mt-24 lg:mt-0 lg:min-w-[30%] lg:border-t-0 lg:p-40  lg:py-36"
                        includeSummaryAccordion
                        includeItemsList
                        isThanksPage={false}
                        dataReference="cart"
                        cart={cartData}
                        isFinalStep={isFinalStep}
                        asSkeleton={isLoading}
                        title={formatCartMessage({ id: 'order.summary', defaultMessage: 'Order summary' })}
                        classNames={{
                            applyDiscountButton: 'py-14 text-16 border-b border-checkout-border lg:border-b-transparent',
                            totalAmount: 'text-18 md:pb-20',
                            subCostsContainer: 'pt-16 flex items-center justify-between capitalize text-secondary-black'
                        }}
                        hasOutOfStockItems={hasOutOfStockItems}
                        button={
                            <>
                                {isFinalStep && termsAndConditionsAll && !isDesktopSize && (
                                    <div className="mb-16 lg:hidden">
                                        <TermsAndConditions
                                            termsAndConditions={termsAndConditionsMaped}
                                            handleTermsChange={handleTermsChange}
                                        />
                                    </div>
                                )}
                                <Button
                                    variant="primary"
                                    disabled={!isFinalStep || processing}
                                    className="w-full"
                                    type="submit"
                                    onClick={handleDebouncedCompetePurchase}
                                    loading={processing}
                                >
                                    {formatCartMessage({ id: 'complete.purchase', defaultMessage: 'Complete purchase' })}
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                className="relative w-[90%] rounded-md bg-white"
                style={{ content: { maxWidth: '400px' } }}
                preventScroll={true}
            >
                <div className="flex flex-col gap-25 p-15 text-center text-base leading-6 md:gap-20 md:p-40">
                    <Markdown markdown={invalidCodeTextContent} className="markdown" />
                    <Button
                        variant="secondary"
                        size="full"
                        onClick={() => {
                            router.push('/');
                        }}
                        className="rounded-md md:rounded-lg"
                    >
                        {goToCartText}
                    </Button>
                    <Button variant="warning" size="full" className="rounded-md md:rounded-lg" onClick={handleModalClick}>
                        {proceedText}
                    </Button>
                </div>
            </Modal>
            <DiscountErrorModal isDiscountErrorModalOpen={isDiscountErrorModalOpen} />
            <Footer logo={logo} />
        </div>
    );
};

const Checkout: FC<CheckoutWrappedProps> = props => (
    <CheckoutProvider>
        <Loqate />
        <CheckoutWrapped {...props} />
    </CheckoutProvider>
);

export default Checkout;
